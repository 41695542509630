import gsap from 'gsap';

const blogDetailEnter = (container) => {

    const tl = gsap.timeline();

    let archiveContent = container.querySelector('.archive__content');
    let singleContent = container.querySelector('.single__content');
    console.log(archiveContent)

    tl
        .fromTo(archiveContent, {autoAlpha: '0', y: '-50'}, { autoAlpha: '1', y: '0', duration: 0.4, ease: 'Power4.out'})
        .fromTo(singleContent, {autoAlpha: '0', y: '-50'}, { autoAlpha: '1', y: '0', duration: 0.4, ease: 'Power4.out'})

    return tl;

}

export default blogDetailEnter;
