import gsap from 'gsap';

const defaultEnter = (container) => {

    const tl = gsap.timeline();

    let banner = container.querySelector('.banner__image');
    if(banner) {
        let bannerImg = banner.querySelector('.img-fluid');
        let bannerBg = banner.querySelector('.banner__bg');
        let content = container.querySelector('.banner__content__inner');
        let rightTop = container.querySelector('.right__top');

        tl
            .fromTo(bannerImg, {autoAlpha: '0', y: '-50'}, { autoAlpha: '1', y: '0', duration: 0.4, ease: 'Power4.out'})
            .fromTo(bannerBg, {autoAlpha: '0', y: '50'}, { autoAlpha: '1', y: '0', duration: 0.4, ease: 'Power4.out'}, '<')
            .fromTo(content, {autoAlpha: '0', y: '50'}, { autoAlpha: '1', y: '0', duration: 0.4, ease: 'Power4.out'})
            .fromTo(rightTop, {autoAlpha: '0'}, { autoAlpha: '1', duration: 0.8, ease: 'Power4.out'}, '<')
    }

    return tl;

}

export default defaultEnter;
