import gsap from 'gsap';
import imageMapResize from 'image-map-resizer';

const pageEnter = (container) => {

    imageMapResize();

    // Set barba prevent to self on fancybox
    let listFancybox = container.querySelectorAll('[data-fancybox]');
    let arrayFancybox = Array.prototype.slice.call(listFancybox);
    for (let i = 0; i < arrayFancybox.length; i++) {
        arrayFancybox[i].setAttribute('data-barba-prevent', 'self');
    }

    window.scrollTo(0,0)


    /**
     * Add scroll class to all area
     */
    let allArea = container.getElementsByTagName('area');
    for (let i = 0; i < allArea.length; i++) {
        allArea[i].classList.add('scroll__to');
    }

    /**
     * Scroll to smooth
     */
    let smooth = container.querySelectorAll('.scroll__to')

    for (let i = 0; i < smooth.length; i++) {
        smooth[i].addEventListener('click',  function (e) {
            e.preventDefault()
            let hash = this.hash.replace(/^.*#/, '');
            let offset = -100;
            let element = document.getElementById(hash);
            let scroll = element.getBoundingClientRect().top + window.pageYOffset + offset;

            window.scrollTo({top: scroll, behavior: 'smooth'});
        });
    }

    /**
     * Menu background images
     */
    let menuImageLeft = container.querySelectorAll('.menu__image--left');
    let menuImageRight = container.querySelectorAll('.menu__image--right');
    let menuImageArrayLeft = Array.prototype.slice.call(menuImageLeft);
    let menuImageArrayRight = Array.prototype.slice.call(menuImageRight);

    let menuActive = container.querySelector('.current-menu-item');
    if (menuActive) {
        let menuActiveLeft = menuActive.querySelector('.menu__data__image--left');
        let menuActiveRight = menuActive.querySelector('.menu__data__image--right');
        if (menuActive !== null) {
            let menuActiveImageLeft = menuActiveLeft.getAttribute('data-image');
            let menuActiveImageRight = menuActiveRight.getAttribute('data-image');

            for (let i = 0; i < menuImageArrayLeft.length; i++) {
                menuImageArrayLeft[i].style.backgroundImage = 'url('+menuActiveImageLeft+')';
            }

            for (let i = 0; i < menuImageArrayRight.length; i++) {
                menuImageArrayRight[i].style.backgroundImage = 'url('+menuActiveImageRight+')';
            }
        }
    }


    let menuBlock = container.querySelector('.menu__block');
    let menuItems = menuBlock.querySelectorAll('.menu-item:not(.current-menu-item)');
    let menuItemsArray = Array.prototype.slice.call(menuItems);


    for (let i = 0; i < menuItemsArray.length; i++) {
        menuItemsArray[i].addEventListener('mouseover', function () {

            let hoverImageLeft = menuItemsArray[i].querySelector('.menu__data__image--left').getAttribute('data-image');
            let hoverImageRight = menuItemsArray[i].querySelector('.menu__data__image--right').getAttribute('data-image');

            if ( hoverImageLeft ) {
                // fade out default images
                for (let i = 0; i < menuImageArrayLeft.length; i++) {
                    menuImageArrayLeft[i].style.opacity = 0;
                }

                // set new images
                let newImageLeft = menuItemsArray[i].querySelector('.menu__data__image--left').getAttribute('data-image');

                for (let i = 0; i < menuImageArrayLeft.length; i++) {
                    menuImageArrayLeft[i].style.backgroundImage = 'url('+newImageLeft+')';
                    menuImageArrayLeft[i].style.opacity = 0.1;
                }
            }

            if ( hoverImageRight ) {
                // fade out default images
                for (let i = 0; i < menuImageArrayRight.length; i++) {
                    menuImageArrayRight[i].style.opacity = 0;
                }

                // set new images
                let newImageRight = menuItemsArray[i].querySelector('.menu__data__image--right').getAttribute('data-image');

                for (let i = 0; i < menuImageArrayRight.length; i++) {
                    menuImageArrayRight[i].style.backgroundImage = 'url('+newImageRight+')';
                    menuImageArrayRight[i].style.opacity = 0.1;
                }
            }
        });
    }


    const tl = gsap.timeline();

    let menuLayer1 = container.querySelector('.menu__layer1');
    let menuLayer2 = container.querySelector('.menu__layer2');

    tl
        .set(menuLayer1, {clearProps: 'all'})
        .fromTo(menuLayer2, {autoAlpha: '1', height: '100%'}, { autoAlpha: '0', duration: 0.3, ease: 'none'}, 0)
        .set(menuLayer2, {clearProps: 'all'})

    return tl;

}

export default pageEnter;
