import gsap from 'gsap';

const menu = (data) => {

    let headerMenu = data.next.container.querySelector('.header__menu__trigger');
    let headerMenuFixed = data.next.container.querySelector('.header__menu__trigger__fixed');
    headerMenu.addEventListener('click', showMenu);
    headerMenuFixed.addEventListener('click', showMenu);

    function showMenu()
    {

        let menu = data.next.container.querySelector('.menu__block')
        let menuLayer1 = data.next.container.querySelector('.menu__layer1')
        const tl = gsap.timeline();

        tl
            .to(menuLayer1, { height: '100%', duration: 0.5, ease: 'power3.inOut'})
            .to(menu, { autoAlpha: 1, pointerEvents: 'auto', duration: 0, ease: 'Power3.out'})
            .to(menuLayer1, { y: '100%', duration: 0.5, ease: 'power4.inOut'}, '<')
            .set(menuLayer1, { clearProps: 'all'})
    }

    let headerCloseMenu = data.next.container.querySelector('.menu__close');
    headerCloseMenu.addEventListener('click', closeMenu);

    function closeMenu()
    {
        let menu = data.next.container.querySelector('.menu__block')
        const tl = gsap.timeline();

        tl
            .to(menu, { autoAlpha: 0, pointerEvents: 'auto', duration: 0.5, ease: 'Power4.out'})
            .set(menu, { clearProps: 'all'})
    }

    let header = data.next.container.querySelector('.header')
    window.onscroll = function () {
        let scroll = window.scrollY;
        if (scroll > 310) {
            header.classList.add('header__fix');
        } else {
            header.classList.remove('header__fix');
        }
    };

}

export default menu;
