import gsap from 'gsap';

const contactEnter = (container) => {

    const tl = gsap.timeline();

    let bannerContact = container.querySelector('.banner__contact');
    let content = container.querySelector('.banner__content__inner');

    tl
        .fromTo(bannerContact, {autoAlpha: '0'}, { autoAlpha: '1', duration: 0.4, ease: 'Power4.out'})
        .fromTo(content, {autoAlpha: '0', y: '-50'}, { autoAlpha: '1', y: '0', duration: 0.4, ease: 'Power4.out'})

    return tl;

}

export default contactEnter;
