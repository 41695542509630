import gsap from 'gsap';

const homeEnter = (container) => {

    const tl = gsap.timeline();

    function matchHeight()
    {

        let i = 0;
        let items = container.querySelectorAll('.blog__content');
        let itemsHeight = [];

        for (i = 0; i < items.length; i++) {
            itemsHeight.push(items[i].offsetHeight);
        }

        let maxHeight = Math.max(...itemsHeight);

        for (i = 0; i < items.length; i++) {
            items[i].style.height = maxHeight + 'px';
        }
    }

    matchHeight();

    let banner = container.querySelector('.banner__image');
    let bannerImg = banner.querySelector('.img-fluid');
    let bannerBg = banner.querySelector('.banner__bg');
    let content = container.querySelector('.banner__content__inner');
    let rightTop = container.querySelector('.right__top');
    let bannerGraan = container.querySelector('.banner__graan');

    tl
        .fromTo(bannerImg, {autoAlpha: '0', y: '-50'}, { autoAlpha: '1', y: '0', duration: 0.4, ease: 'Power4.out'})
        .fromTo(bannerBg, {autoAlpha: '0', y: '50'}, { autoAlpha: '1', y: '0', duration: 0.4, ease: 'Power4.out'}, '<')
        .fromTo(content, {autoAlpha: '0', y: '50'}, { autoAlpha: '1', y: '0', duration: 0.4, ease: 'Power4.out'})
        .fromTo(rightTop, {autoAlpha: '0'}, { autoAlpha: '1', duration: 0.8, ease: 'Power4.out'}, '<')
        .fromTo(bannerGraan, {autoAlpha: '0'}, { autoAlpha: '1', duration: 0.8, ease: 'Power4.out'}, '<')

    return tl;

}

export default homeEnter;
