
const bodyclass = () => {

    // set bodyclass to correct page
    let bodyClassList = document.getElementById('body__class').classList;
    let body = document.getElementsByTagName('body')
    body[0].className = bodyClassList;
}

export default bodyclass;
