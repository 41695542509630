import 'jquery';

const gravityform = (container) => {
    function loadjscssfile(filename, filetype) {
        if (filetype == 'js') {
            //if filename is a external JavaScript file
            const existingScript = document.querySelector('script[src="${filename}"]');
            if (existingScript) {
                existingScript.remove();
            }
            let fileref = document.createElement('script');
            fileref.setAttribute('type', 'text/javascript');
            fileref.setAttribute('src', filename);
        }
    }

    // GRAVITY FORMS
    const baseURL = window.location.hostname;
    const protocol = window.location.protocol;

    // Here we are manually reloading the gravity form scripts and styles. If you find that you get errors in future with missing assets, simply add them below.
    const gravityFormJS =
        '/app/plugins/gravityforms/js/gravityforms.min.js';
    const gravityFormsJquery =
        '/app/plugins/gravityforms/js/jquery.json.js';
    const gravityFormsConditionalLogic =
        '/app/plugins/gravityforms/js/conditional_logic.js';
    const gformVariables =
        'var gf_global = {"gf_currency_config":{"name":"Australian Dollar","symbol_left":"$","symbol_right":"","symbol_padding":" ","thousand_separator":",","decimal_separator":".","decimals":2},"base_url":"' +
        protocol +
        '//' +
        baseURL +
        '/app/plugins/gravityforms","number_formats":[],"spinnerUrl":"' +
        protocol +
        '//' +
        baseURL +
        '/app/plugins/gravityforms/images/spinner.gif"};';

    const gformWrapper = container.querySelectorAll('.gform_wrapper');

    if (gformWrapper) {
        // run if the page contains a form
        const gformVariablesScript = document.createElement('script');
        gformVariablesScript.innerHTML = gformVariables;
        document.body.appendChild(gformVariablesScript);
        loadjscssfile(gravityFormJS, 'js');
        loadjscssfile(gravityFormsJquery, 'js');
        loadjscssfile(gravityFormsConditionalLogic, 'js');

        gformWrapper.forEach((element) => {
            const parent = element.parentElement;
            const scripts = parent.querySelectorAll('script');
            scripts.forEach((script) => {
                const scriptCode = script.innerHTML;
                const newScript = document.createElement('script');
                script.remove();
                newScript.innerHTML = scriptCode;
                parent.appendChild(newScript);
            });
        });
    }


}

export default gravityform;
