import gsap from 'gsap';

const blogEnter = (container) => {

    const tl = gsap.timeline();

    let archiveContent = container.querySelector('.archive__content');
    let blogContent = container.querySelectorAll('.blog__content');

    tl
        .fromTo(archiveContent, {autoAlpha: '0', y: '-50'}, { autoAlpha: '1', y: '0', duration: 0.4, ease: 'Power4.out'})
        .fromTo(blogContent, {autoAlpha: '0', y: '-50'}, { autoAlpha: '1', y: '0', duration: 0.4, stagger: 0.2, ease: 'Power4.out'})

    return tl;

}

export default blogEnter;
