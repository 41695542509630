const MeatOrder = (container) => {
    const orderCounts = container.getElementsByClassName('order__count');
    const orderCountsGrams = container.getElementsByClassName('order__count__grams');
    const productList = document.getElementById('product-list');

    // eslint-disable-next-line no-unused-vars
    const $totalPriceHtml = document.getElementById('total_price');

    const $formTotalPrice = document.getElementById('input_total_price');
    const $formMeatPrice = document.getElementById('input_meat_price');
    const $formPackagesPrice = document.getElementById('input_packages_price');

    Array.from(orderCountsGrams).forEach(function (element) {
        element.addEventListener('change', function (e) {
            const $kgField = document.getElementById(e.target.getAttribute('data-kg-field-id'));
            const $kiloGrams = roundAccurately((e.target.value) * 0.001);

            $kgField.innerHTML = $kiloGrams;

            maintainProductList(element, e, false);

            calculatePrice(e, $kiloGrams);
        });
    });

    Array.from(orderCounts).forEach(function (element) {
        element.addEventListener('change', function (e) {
            maintainProductList(element, e, true);
            const $count = (e.target.value);
            calculatePrice(e, $count);
        });
    });
    const productsInShoppingCart = [];

    const roundAccurately = (number) => {

        return Math.ceil(number * 10) / 10;
    }

    function getKeyByValue(object, value) {
        return Object.keys(object).find(key => object[key]['postId'] === value);
    }

    function maintainProductList(element, e, isPackage){
        const amount = isPackage ? e.target.value : roundAccurately((e.target.value) * 0.001);
        const existsInList = productList.contains(document.getElementById('product-' + e.target.getAttribute('data-kg-field-id')));
        const productPrice = isPackage ? amount * e.target.getAttribute('data-price') : amount * e.target.getAttribute('data-price');
        const productName = e.target.getAttribute('data-post-name');

        if (amount > 0) {
            // Only add element if it is not already in the list
            if (!existsInList)  addProductToTotal(productName, e.target.value, productPrice, 'product-' + e.target.getAttribute('data-kg-field-id'), isPackage);
            else {
                // Remove the old row and replace it with a new updated row
                removeRowFromProducts('product-' + e.target.getAttribute('data-kg-field-id'));
                addProductToTotal(productName, e.target.value, productPrice, 'product-' + e.target.getAttribute('data-kg-field-id'), isPackage);
            }
        } else {
            // Remove the row if it exists in the list
            if (existsInList) removeRowFromProducts('product-' + e.target.getAttribute('data-kg-field-id'));
        }
    }

    function addProductToTotal(name, gram, total, id, isPackage){
        const newRow = productList.insertRow();

        // Give the row an id so that we can remove it later
        newRow.id = id;

        const nameCell = newRow.insertCell();
        nameCell.innerText = name;

        const gramCell = newRow.insertCell();
        gramCell.innerText = isPackage ? gram : gram + ' gram';

        const totalCell = newRow.insertCell();
        totalCell.innerText = '€'+ total.toFixed(2).replace('.', ',');
    }

    function removeRowFromProducts(id){
        document.getElementById(id).remove();
    }

    function calculatePrice(e, $count) {
        const $postId = (e.target.getAttribute('data-post-id'));
        const $category = e.target.getAttribute('data-category-id');
        const $price = e.target.getAttribute('data-price');
        const $existing = getKeyByValue(productsInShoppingCart, $postId);
        //create new dataobject and check if this product is already in shopping cart.
        const $dataObject = {postId: $postId, count: $count, price: $price, category: $category};

        if ($existing !== undefined) {
            productsInShoppingCart[$existing] = $dataObject;
        } else {
            productsInShoppingCart.push($dataObject);
        }
        //check all prices and calculate
        let $totalPrice = 0;
        let $totalPriceMeat = 0;
        let $totalPricePackages = 0;
        for (let i = 0; i < productsInShoppingCart.length; i++) {
            let $elementPrice = (productsInShoppingCart[i]['count'] * parseFloat(productsInShoppingCart[i]['price'].replace(/,/g, '.')))
            $totalPrice += $elementPrice;
            if (productsInShoppingCart[i]['category'] === 'Vlees') {
                $totalPriceMeat += $elementPrice;
            } else if (productsInShoppingCart[i]['category'] === 'Pakket') {
                $totalPricePackages += $elementPrice;
            }
        }
        //parse prices to correct html syntax
        $totalPrice = $totalPrice.toFixed(2).replace('.', ',');
        $totalPriceMeat = $totalPriceMeat.toFixed(2).replace('.', ',');
        $totalPricePackages = $totalPricePackages.toFixed(2).replace('.', ',');
        //set the price for user to see
        $totalPriceHtml.innerHTML = $totalPrice;
        //add the price to the form for the submit.
        $formMeatPrice.value = $totalPriceMeat;
        $formPackagesPrice.value = $totalPricePackages;
        $formTotalPrice.value = $totalPrice;
    }
}

export default MeatOrder;
