import gsap from 'gsap';

import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger)

const cowPercent = (data) => {
    let check = document.body.classList.contains('page-heerlijk-vlees-data')

    if(check) {
        let leftCow = data.next.container.querySelector('.vlees__item__left');
        let leftCowBlue = leftCow.querySelector('.vlees__cow__blue')
        let leftCowCount = leftCow.querySelector('.vlees__cow__count')
        let leftCowPercent = leftCow.querySelector('.vlees__cow__percent')
        let leftCowWidth = leftCowBlue.dataset.cowWidth;

        gsap.to(leftCowBlue, {
            scrollTrigger: {
                trigger: leftCow,
                start: 'top center',
            },
            width: leftCowWidth + '%',
            duration: 2,
        });

        gsap.to(leftCowCount, {
            scrollTrigger: {
                trigger: leftCow,
                start: 'top center',
            },
            left: leftCowWidth + '%',
            duration: 2,
        });

        let counter = {
            value: leftCowPercent.textContent,
        }

        gsap.to(counter, {
            scrollTrigger: {
                trigger: leftCow,
                start: 'top center',
            },
            value: leftCowWidth,
            duration: 2,
            onUpdate: () => {
                leftCowPercent.innerHTML = Math.trunc(counter.value) + '%';
            },
        })


        let rightCow = data.next.container.querySelector('.vlees__item__right');
        let rightCowBlue = rightCow.querySelector('.vlees__cow__blue')
        let rightCowCount = rightCow.querySelector('.vlees__cow__count')
        let rightCowPercent = rightCow.querySelector('.vlees__cow__percent')
        let rightCowWidth = rightCowBlue.dataset.cowWidth;

        gsap.to(rightCowBlue, {
            scrollTrigger: {
                trigger: rightCow,
                start: 'top center',
            },
            width: rightCowWidth + '%',
            duration: 2,
        });

        gsap.to(rightCowCount, {
            scrollTrigger: {
                trigger: rightCow,
                start: 'top center',
            },
            left: rightCowWidth + '%',
            duration: 2,
        });

        gsap.to(counter, {
            scrollTrigger: {
                trigger: rightCow,
                start: 'top center',
            },
            value: rightCowWidth,
            duration: 2,
            onUpdate: () => {
                rightCowPercent.innerHTML = Math.trunc(counter.value) + '%';
            },
        })
    }
}

export default cowPercent;
