export default {
    init: function () {
    },
    finalize() {


        $(function() {

        });
    },
};
